@import '../../variables';

.MainDashboard {
  display: flex;
  flex-direction: column;
  height: 100%;

  .MainContent {
    overflow: auto;

    @media (max-width: $breakpoint-mobile) {
      margin-bottom: 2em;
    }

    .MobileBottomUserButtons {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 2;
      width: 100%;
    }
  } 
}