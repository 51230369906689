@import '../../../variables';

.PlaylistsPlayerPopup {
  position: relative;
  background: #fff;
  padding: 0.6em 0.8em;
  border: 1px solid $playlistBlue;
  border-radius: 1em;
  width: 85vw;
  max-width: 20em;
  font-family: Arial;
  font-size: 0.9em;
  font-weight: 500;
  user-select: none;

  @media (min-width: $breakpoint-tablet) {
    position: absolute;
    z-index: 3;
    bottom: 60px;
    max-width: 16em;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      border: 12px solid;
      border-color: transparent #000 #000 transparent;
      margin-top: -12px;
    }

    &.HideAfterPseudo {
      &::after {
        display: none;
      }
    }
  }

  button {
    background-color: #000;
    color: $white;
    border: none;
    text-transform: uppercase;
    padding: 0.3em;
    border-radius: 0.5em;
    width: 100%;
    max-width: 7em;
    cursor: pointer;
    font-family: inherit;

    &:hover {
      opacity: 0.8;
    }
  }

  .PP-ListContainer {
    font-size: 85%;

    .PP-Loading {
      height: 2em;
      margin-bottom: 0.3em;
    }

    .PP-Error {
      text-align: center;
      margin: 1em 0;
    }

    .PP-List { 
      margin-bottom: 1em;
      height: 20em;
      overflow-y: scroll;

      @media (min-width: $breakpoint-tablet) {
        height: 9em;
      }

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.3em 0;
        border-bottom: 1px solid $videoDetailstItems;

        &:first-of-type {
          border-top: 1px solid $videoDetailstItems;
        }

        button.hasItem {
          background: rgba(0, 0, 0, 0.4);
        }

        span {
          color: $playlistBlue;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding-right: 1em;
        }
      }
    }

    .PP-Footer {
      color: $videoDetailstItems;
      cursor: pointer;
      text-transform: uppercase;
      margin-bottom: 1em;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        margin-right: 0.3em;
        font-size: 60%;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .PP-Header {
    margin-bottom: 0.5em;

    .PPH-Inner {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 0.5em;
      padding-right: 1.8em;

      .PP-Title {
        text-transform: uppercase;
        color: $playlistBlue;
      }

      .PP-Subtitle {
        font-size: 70%;
        color: #a6a6a6;
      }
    }

    .PP-InputContainer {
      position: relative;
      margin-bottom: 0.5em;

      input {
        width: 100%;
        border: 1px solid #000;
        border-radius: 0.5em;
        padding: 0.8em 3em 0.8em 0.6em;

        &:focus {
          outline: none;
        }
      }

      i {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 85%;
        color: $videoDetailstItems;
      }
    }

    .PP-CreateContainer {
      text-align: center;
    }

    .PP-UpdateContainer {
      display: flex;
      justify-content: space-between;

      .DeleteBtn {
        background-color: #a6a6a6;
      }
    }

    a {
      text-transform: uppercase;
      color: $videoDetailstItems;
      font-size: 80%;

      &:hover {
        opacity: 0.7;
      }
    }

    button {
      padding: 0.5em 1em;
      font-size: 80%;
      width: auto;
      max-width: initial;
    }
  }
}

// mobile view
.PP-Overlay {
  .PP-ClosePopupIcon {
    position: absolute;
    right: 5px;
    bottom: 100%;
    z-index: 1;
    color: $white;
    font-size: 2em;
    display: inline-block;
    width: 30px;
    height: 35px;
    text-align: center;

    @media (min-width: $breakpoint-tablet) {
      top: 0;
      color: rgba(0, 0, 0, 0.3);
      font-size: 1em;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  @media (max-width: $breakpoint-tablet) {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 901;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.PP-DeleteModal {
  position: fixed;
  inset: 0;
  z-index: 4;
  background: rgba(0, 0, 0, 0.5);
  display: flex;

  .PPD-Inner {
    border: 1px solid rgba(255, 255, 255, 0.5);
    width: 100%;
    max-width: 270px;
    background: $white;
    color: #000;
    padding: 0.5em 1em 1em;
    border-radius: 0.5em;
    font-size: 1.2em;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translateX(-50%);

    .PPD-Title {
      margin-bottom: 0.5em;
      color: $playlistBlue;
    }

    .PPD-Close {
      position: absolute;
      top: 0;
      right: 10px;
      color: rgba(0, 0, 0, 0.3);
      font-size: 80%;
      cursor: pointer;
      padding: 0.2em;

      &:hover {
        opacity: 0.7;
      }
    }

    .PPD-Content {
      text-align: center;

      .PP-DeleteButton {
        background-color: rgba(255, 0, 0, 0.8);
        color: $white;
        border: none;
        border-radius: 0.5em;
        text-transform: uppercase;
        padding: 0.3em;
        margin: 0 0.5em;
        width: 100%;
        max-width: 5em;
        cursor: pointer;
        font-family: inherit;
        font-size: 0.7em;

        &.Cancel {
          background-color: #a6a6a6;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
